import PageTitle from '../components/pagetitle'; // Keep this import
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {

    const titleData = { title: "Contato", description: "Entre em contato conosco" };
    const form = useRef();
    
    const sendEmail = (e) => {
        e.preventDefault();
    
        const mailApiKey = process.env.MAIL_API_KEY;
        console.log(mailApiKey);

        emailjs.sendForm(
            'service_w8xlpva',
            'template_dgnvvqi',
            form.current, {
                publicKey: 'gahHhvm9GJZm5dACx',
            }
        ).then(
            () => {
                alert('Email enviado com sucesso!');  
                console.log('SUCCESS!');
            },
            (error) => {
                alert('Erro ao enviar email. Tente novamente mais tarde.');
                console.log('FAILED...', error.text);
            },
        );
    };

    return (
        <div className='inner-page'>
            {<PageTitle data = {titleData} />}

            <section className="tf-section project-info">
                <div className="container"> 
                    <div className="row">
                        <div className="col-md-12">
                            <form ref={form} onSubmit={sendEmail} className="form-contact">
                                <div className="project-info-form">
                                    <h6 className="title">Deixe uma mensagem</h6>
                                    <div className="form-inner">
                                        <fieldset>
                                            <label>Nome</label>
                                            <input type="text" id="name" name="user_name" placeholder="Informe seu nome" required />
                                        </fieldset>
                                        <fieldset>
                                            <label>Email</label>
                                            <input type="email" id="email" name="user_email" placeholder="Informe seu email" required />
                                        </fieldset>
                                        <fieldset>
                                            <label htmlFor="message">Mensagem</label>
                                            <textarea id="message" name="message" placeholder="Infome a sua mensagem" rows="5" equired></textarea>
                                        </fieldset> 
                                    </div>
                                </div> 

                                <div className="wrap-btn">
                                    <button type="submit" className="tf-button style1">
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;
