import React from 'react';
import PageTitle from '../components/pagetitle';

function Privacy(props) {
    const titleData = { title: "Privacidade", description: "Política de Privacidade" };

    return (
        <div className='inner-page'>
            {<PageTitle data = { titleData } />}

            <section className="tf-section tf-team-details pt60">
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-12">
                            <div className="team-details">
                                <div className="content">
                                    <h6>Última atualização: 11 de outubro de 2024</h6>
                                    <p>Bem-vindo à Or Games! Esta Política de Privacidade descreve como lidamos com as informações coletadas dos usuários que visitam nosso site <a href="https://orgames.net">https://orgames.net</a> e jogam nossos jogos, disponíveis no Google Play, Steam e App Store.</p>

                                    <div className="spacing"></div>
                                    <h6>1. Informações que Coletamos</h6>
                                    <p>Atualmente, não coletamos nenhuma informação pessoal dos usuários que visitam nosso site.</p>
                                    <p>Nossos jogos utilizam apenas os nicknames dos jogadores para fornecer um sistema de ranking visível para outros jogadores. Nenhuma outra informação pessoal é coletada.</p>

                                    <div className="spacing"></div>
                                    <h6>2. Uso das Informações</h6>
                                    <p>Os nicknames fornecidos são usados exclusivamente para exibir a posição dos jogadores no ranking dos jogos. Essas informações serão visíveis para outros jogadores, permitindo que vejam sua posição no sistema de classificação.</p>
                                    <p>Utilizamos cookies apenas para garantir o funcionamento básico do site. O usuário pode gerenciar as preferências de cookies diretamente no navegador.</p>

                                    <div className="spacing"></div>
                                    <h6>3. Compartilhamento de Dados</h6>
                                    <p>Não compartilhamos suas informações com nenhuma organização de terceiros. Os nicknames dos jogadores são usados apenas para o recurso de classificação dentro de nossos jogos e não são vendidos, trocados ou transferidos fora do ambiente dos jogos.</p>

                                    <div className="spacing"></div>
                                    <h6>4. Direitos dos Usuários</h6>
                                    <p>Você tem o direito de:</p>
                                    <ul className="bullet-point">
                                        <li className="bullet-point">Solicitar informações sobre os dados coletados (atualmente não coletamos dados pessoais).</li>
                                    </ul>
                                    <p>Para exercer seus direitos, entre em contato pelo e-mail: <a href="mailto:contact@orgames.net">contact@orgames.net</a>.</p>

                                    <div className="spacing"></div>
                                    <h6>5. Segurança das Informações</h6>
                                    <p>Tomamos medidas razoáveis para proteger as informações que coletamos contra acesso, uso ou divulgação não autorizados. No entanto, nenhum sistema pode ser completamente seguro, e não podemos garantir a segurança absoluta de suas informações.</p>
                                    <p>Implementamos medidas técnicas e organizacionais para proteger as informações contra acesso não autorizado, alteração, divulgação ou destruição.</p>

                                    <div className="spacing"></div>
                                    <h6>6. Privacidade das Crianças</h6>
                                    <p>Nossos jogos podem ser jogados por crianças, porém não coletamos intencionalmente informações pessoais de crianças menores de 13 anos. Se tomarmos conhecimento de que uma criança menor de 13 anos nos forneceu informações pessoais, excluiremos essas informações o mais rápido possível.</p>

                                    <div className="spacing"></div>
                                    <h6>7. Mudanças nesta Política de Privacidade</h6>
                                    <p>Podemos atualizar esta Política de Privacidade periodicamente. Quaisquer mudanças serão postadas nesta página, e a data de "Última atualização" no topo refletirá quando as mudanças foram feitas. Encorajamos você a revisar esta Política de Privacidade periodicamente para quaisquer atualizações.</p>
                                    <p>Notificaremos os usuários sobre quaisquer alterações importantes através do site.</p>

                                    <div className="spacing"></div>
                                    <h6>8. Contato</h6>
                                    <p>Se você tiver alguma dúvida ou preocupação sobre esta Política de Privacidade, entre em contato conosco pelo e-mail: <a href="mailto:contact@orgames.net">contact@orgames.net</a>.</p>

                                    <p>Agradecemos por confiar na Or Games! Ao usar nosso site ou jogos, você concorda com a coleta e uso das suas informações conforme descrito nesta Política de Privacidade.</p>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Privacy;