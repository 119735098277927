import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './App.scss';
import ScrollToTop from "./ScrollToTop";
import TagManager from 'react-gtm-module';

// Initialize Google Tag Manager
const tagManagerArgs = {
    gtmId: 'GTM-NKFFKP2S'
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Fragment>
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>
    </React.Fragment>
);


