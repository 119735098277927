
const dataRoadmap = [
    {
        id: 1,
        time: '2006',
        text1: 'A semente é plantada.',
        text2: 'Enquanto Marllon, fundador da Or Games, conversava com Deus sobre seus estudos e paixão por video-games e filmes, o Senhor lhe concede um desejo de usar essa paixão para alcançar pessoas que provavelmente nunca abrirão uma Bíblia para ler.',
        text3: '',
        text4: '',
        status: 'active'
    }, 
    {
        id: 2,
        time: 'Abril de 2008',
        text1: 'O início de uma jornada!',
        text2: '',
        text3: 'Marllon aprende a trabalhar com games do estilo MMORPG, onde as quests desses games eram modificadas para histórias bíblicas, levando os jogadores a entender o verdadeiro significado de eventos como Páscoa e Natal.',
        text4: '',
        status: 'active'
    },
    {
        id: 3,
        time: 'Julho de 2012',
        text1: 'Marllon finaliza o Ensino Superior na UniFacisa e recebe a certificação de "Tecnólogo em Jogos Digitais".',
        text2: '',
        text3: '',
        text4: '',
        status: 'active'
    },
    {
        id: 4,
        time: 'Março de 2015',
        text1: 'Marllon é contratado como líder de desenvolvimento de Jogos Educativos pelo "Instituto Alfa e Beto".',
        text2: '',
        text3: '',
        text4: '',
        status: 'active'
    },
    {
        id: 5,
        time: 'Fevereiro de 2019',
        text1: 'Marllon se muda com sua família para Hamburgo, na Alemanha, para trabalhar como Engenheiro de Software na empresa "Good Games".',
        text2: '',
        text3: '',
        text4: '',
        status: 'active'
    },
    {
        id: 6,
        time: 'Setembro de 2022',
        text1: 'Marllon retorna com sua família para o Brasil, é contratado pela empresa "Ello Technology", atuando como Engenheiro Líder da equipe de Unity.',
        text2: '',
        text3: '',
        text4: '',
        status: 'active'
    },
    {
        id: 7,
        time: 'Janeiro de 2023',
        text1: 'Após anos trabalhando em corporações, a semente plantada em 2006 começa a queimar novamente no coração de Marllon.',
        text2: '',
        text3: '',
        text4: '',
        status: 'active'
    },
    {
        id: 8,
        time: 'Agosto de 2024',
        text1: 'O início da Missão!',
        text2: '',
        text3: 'Marllon decide fundar a "Or Games" para trabalhar em tempo integral com o objetivo de propagar o evangelho de Jesus Cristo através de Games Bíblicos.',
        text4: '',
        status: 'active'
    },
    {
        id: 9,
        time: 'Outubro de 2024',
        text1: 'O primeiro produto da Or Games será lançado na Steam:',
        text2: '"Bíblia: Quebra-cabeças"',
        text3: '',
        text4: 'O jogador aprenderá sobre passagens bíblicas através de histórias animadas, e então poderá monstar quebra-cabeças das cenas bíblicas apresentadas.',
        status: ''
    }


]

export default dataRoadmap;