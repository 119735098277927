import React, { useEffect, useState } from 'react';

import './styles.scss'

import { Link } from 'react-router-dom';



function Footer(props) {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
    return (
        <footer id="footer">
        <div className="footer-main">
            <div className="container">
                <div className="row">
                    <div className="footer-logo">
                        <div className="logo_footer">
                            <img src={require ("../../assets/images/logo/orgames-logo2.png")} alt="" />
                        </div>
                        <p>Ide por todo o mundo, e pregai o evangelho a toda criatura. <i>(Marcos 16:15)</i></p>
                    </div>
                    {/* <div className="widget">
                        <h5 className="widget-title">
                            Contact us
                        </h5>
                        <ul className="widget-link contact">
                            <li>
                                <p>Address</p>
                                <Link to="#">1901 Thornridge Cir. Shiloh, Hawaii 81063</Link>
                            </li>
                            <li>
                                <p>Phone</p>
                                <Link to="#">+33 7 00 55 57 60</Link>
                            </li>
                            <li className="email">
                                <p>Email</p>
                                <Link to="#">risebot@support.com</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="widget support">
                        <h5 className="widget-title">
                            Support
                        </h5>
                        <ul className="widget-link">
                            <li>
                                <Link to="/connect">Connect Wallet</Link>
                            </li>
                            <li>
                                <Link to="/forgetPass">Forget Password</Link>
                            </li>
                            <li>
                                <Link to="/faqs">FAQs</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="widget link">
                        <h5 className="widget-title">
                            Quick link
                        </h5>
                        <ul className="widget-link">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/project_list">Project</Link>
                            </li>
                            <li>
                                <Link to="/blog_grid">Blog</Link>
                            </li>
                            <li>
                                <Link to="/team_details">Our Team</Link>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="wrap-fx">
                    <div className="Copyright">
                        Copyright © 2024 | Sandolkakos Desenvolvimento de Software LTDA | CNPJ: 47.539.926/0001-13
                    </div>
                </div>
            </div>
            
        </div>

        {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
    </footer>
    );
}

export default Footer;