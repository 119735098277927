import img1 from '../images/features/dream.png';
import img2 from '../images/features/desire.png';
import img3 from '../images/features/mission.png';

const dataFeatured = [
    {
        id: 1,
        img: img1,
        title: 'Um Sonho',
        text: 'Concebido por Deus em 2006.',
    },
    {
        id: 2,
        img: img2,
        title: 'Um Desejo',
        text: 'Alcançar aqueles que talvez nunca abrirão uma Bíblia para ler.',
    }, 
    {
        id: 3,
        img: img3,
        title: 'Uma Missão',
        text: 'Produzir conteúdo Cristão de qualidade para nossas crianças e jovens.',
    },
]

export default dataFeatured;